import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './profile-user-identity.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ProfileUserIdentityDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { profileUserIdentityId: id } = useParams<'profileUserIdentityId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_USER_IDENTITY));
  }, []);

  const profileUserIdentityEntity = useAppSelector(state => state.profileUserIdentity.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card card-grey p-2">
            <h2 data-cy="profileUserIdentityDetailsHeading">
              <Translate contentKey="iamdentityApp.profileUserIdentity.detail.title">ProfileUserIdentity</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <Translate contentKey="iamdentityApp.profileUserIdentity.profileDefinition">Profile Definition</Translate>
              </dt>
              <dd>{profileUserIdentityEntity.profileDefinition ? profileUserIdentityEntity.profileDefinition.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.profileUserIdentity.application">Application</Translate>
              </dt>
              <dd>{profileUserIdentityEntity.application ? profileUserIdentityEntity.application.name : ''}</dd>
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{profileUserIdentityEntity.id}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.description">Description</Translate>
                </span>
              </dt>
              <dd>{profileUserIdentityEntity?.description}</dd>
              <dt>
                <span id="retrieveUserNameFromDependentIdentity">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.retrieveUserNameFromDependentIdentity">
                    Retrieve User Name From Dependent Identity
                  </Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity?.retrieveUserNameFromDependentIdentity == true
                  ? translate('global.yes')
                  : profileUserIdentityEntity?.retrieveUserNameFromDependentIdentity == false
                  ? translate('global.no')
                  : ''}
              </dd>
              <dt>
                <span id="dependsOn">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.dependsOn">Depends On</Translate>
                </span>
              </dt>
              <dd>
                {(profileUserIdentityEntity?.dependsOn?.id ? profileUserIdentityEntity.dependsOn.id : '') +
                  (profileUserIdentityEntity?.dependsOn?.description ? ' - ' + profileUserIdentityEntity.dependsOn.description : '')}
              </dd>
              <dt>
                <span id="userNameExpression">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.userNameExpression">User Name Expression</Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity.userNameExpression ? (
                  <CompactExpressionBuilder
                    tree={profileUserIdentityEntity.userNameExpression}
                    expressionId={`profile-user-identity-${profileUserIdentityEntity?.id || ''}`}
                  />
                ) : null}
              </dd>
              <dt>
                <span id="alternativeUserNameExpression">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.alternativeUserNameExpression">
                    Alternative User Name Expression
                  </Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity.alternativeUserNameExpression ? (
                  <CompactExpressionBuilder tree={profileUserIdentityEntity.alternativeUserNameExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="associatedEmailAddressExpression">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.associatedEmailAddressExpression">
                    Associated Email Address Expression
                  </Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity.associatedEmailAddressExpression ? (
                  <CompactExpressionBuilder tree={profileUserIdentityEntity.associatedEmailAddressExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="displayNameExpression">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.displayNameExpression">Display Name Expression</Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity.displayNameExpression ? (
                  <CompactExpressionBuilder tree={profileUserIdentityEntity.displayNameExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="initialPasswordExpression">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.initialPasswordExpression">
                    Initial Password Expression
                  </Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity.initialPasswordExpression ? (
                  <CompactExpressionBuilder tree={profileUserIdentityEntity.initialPasswordExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="mustChangePasswordOnFirstLoginExpression">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.mustChangePasswordOnFirstLoginExpression">
                    Must Change Password On First Login Expression
                  </Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity.mustChangePasswordOnFirstLoginExpression ? (
                  <CompactExpressionBuilder tree={profileUserIdentityEntity.mustChangePasswordOnFirstLoginExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="dateNextChangePasswordExpression">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.dateNextChangePasswordExpression">
                    Date Next Change Password Expression
                  </Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity.dateNextChangePasswordExpression ? (
                  <CompactExpressionBuilder tree={profileUserIdentityEntity.dateNextChangePasswordExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity.startDate ? (
                  <TextFormat value={profileUserIdentityEntity.startDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.profileUserIdentity.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityEntity.endDate ? (
                  <TextFormat value={profileUserIdentityEntity.endDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment key={cad.id}>
                  {foundCustomAttributeID(cad, profileUserIdentityEntity, locale) && (
                    <>
                      <dt>
                        <span id={cad.displayName}>{cad.displayName}</span>
                      </dt>
                      <dd>{findCustomAttributeValueByKey(cad, profileUserIdentityEntity, locale)}</dd>
                    </>
                  )}
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{profileUserIdentityEntity.createdBy ? profileUserIdentityEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {profileUserIdentityEntity.createdDate ? (
                  <TextFormat value={profileUserIdentityEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{profileUserIdentityEntity.lastModifiedBy ? profileUserIdentityEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {profileUserIdentityEntity.lastModifiedDate ? (
                  <TextFormat value={profileUserIdentityEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Card>
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.PROFILE_USER_IDENTITY} />
    </React.Fragment>
  );
};

export default ProfileUserIdentityDetail;
