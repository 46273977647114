import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, TextFormat } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './technical-order-action-create.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const TechnicalOrderActionCreateDetail = () => {
  const dispatch = useAppDispatch();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { technicalOrderActionUpdateId: id } = useParams<'technicalOrderActionUpdateId'>();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const technicalOrderActionCreateEntity = useAppSelector(state => state.technicalOrderActionCreate.entity);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card card-grey p-2">
            <h2 data-cy="technicalOrderActionCreateDetailsHeading">
              <Translate contentKey="iamdentityApp.technicalOrderActionCreate.detail.title">TechnicalOrderActionCreate</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{technicalOrderActionCreateEntity.id}</dd>
              <dt>
                <span id="createForUserIdentity">
                  <Translate contentKey="iamdentityApp.technicalOrderActionCreate.createForUserIdentity">
                    Create For User Identity
                  </Translate>
                </span>
              </dt>
              <dd>{technicalOrderActionCreateEntity.createForUserIdentity ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="createForRole">
                  <Translate contentKey="iamdentityApp.technicalOrderActionCreate.createForRole">Create For Role</Translate>
                </span>
              </dt>
              <dd>{technicalOrderActionCreateEntity.createForRole ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="createForGroup">
                  <Translate contentKey="iamdentityApp.technicalOrderActionCreate.createForGroup">Create For Group</Translate>
                </span>
              </dt>
              <dd>{technicalOrderActionCreateEntity.createForGroup ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="createForSpecificPermission">
                  <Translate contentKey="iamdentityApp.technicalOrderActionCreate.createForSpecificPermission">
                    Create For Specific Permission
                  </Translate>
                </span>
              </dt>
              <dd>{technicalOrderActionCreateEntity.createForSpecificPermission ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="operationType">
                  <Translate contentKey="iamdentityApp.technicalOrderActionCreate.operationType">Operation Type</Translate>
                </span>
              </dt>
              <dd>{technicalOrderActionCreateEntity.operationType}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.technicalOrderActionCreate.connectorCommand">Connector Command</Translate>
              </dt>
              <dd>{technicalOrderActionCreateEntity.connectorCommand ? technicalOrderActionCreateEntity.connectorCommand.code : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.technicalOrderActionCreate.application">Application</Translate>
              </dt>
              <dd>{technicalOrderActionCreateEntity.application ? technicalOrderActionCreateEntity.application.name : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{technicalOrderActionCreateEntity.createdBy ? technicalOrderActionCreateEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {technicalOrderActionCreateEntity.createdDate ? (
                  <TextFormat value={technicalOrderActionCreateEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{technicalOrderActionCreateEntity.lastModifiedBy ? technicalOrderActionCreateEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {technicalOrderActionCreateEntity.lastModifiedDate ? (
                  <TextFormat
                    value={technicalOrderActionCreateEntity.lastModifiedDate}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            <Button tag={Link} to={`/technical-order-action-create/${technicalOrderActionCreateEntity.id}/edit`} color="warning">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Card>
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.TECHNICAL_ORDER_ACTION_CREATE} />
    </React.Fragment>
  );
};

export default TechnicalOrderActionCreateDetail;
