import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './recon-session-user-identity-systems-not-in-iam.reducer';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ReconSessionUserIdentitySystemsNotInIamDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const reconSessionUserIdentitySystemsNotInIamEntity = useAppSelector(state => state.reconSessionUserIdentitySystemsNotInIam.entity);
  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card card-grey p-2">
            <h2 data-cy="reconSessionUserIdentitySystemsNotInIamDetailsHeading">
              <Translate contentKey="iamdentityApp.reconSessionUserIdentitySystemsNotInIam.detail.title">
                ReconSessionUserIdentitySystemsNotInIam
              </Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserIdentitySystemsNotInIamEntity.id}</dd>
              <dt>
                <span id="applicationCode">
                  <Translate contentKey="iamdentityApp.reconSessionUserIdentitySystemsNotInIam.applicationCode">Application Code</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserIdentitySystemsNotInIamEntity.applicationCode}</dd>
              <dt>
                <span id="userBusinessKey">
                  <Translate contentKey="iamdentityApp.reconSessionUserIdentitySystemsNotInIam.userBusinessKey">
                    User Business Key
                  </Translate>
                </span>
              </dt>
              <dd>{reconSessionUserIdentitySystemsNotInIamEntity.userBusinessKey}</dd>
              <dt>
                <span id="userName">
                  <Translate contentKey="iamdentityApp.reconSessionUserIdentitySystemsNotInIam.userName">User Name</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserIdentitySystemsNotInIamEntity.userName}</dd>
              <dt>
                <span id="processed">
                  <Translate contentKey="iamdentityApp.reconSessionUserIdentitySystemsNotInIam.processed">Processed</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserIdentitySystemsNotInIamEntity.processed ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserIdentitySystemsNotInIam.processedBy">Processed By</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentitySystemsNotInIamEntity.processedBy
                  ? reconSessionUserIdentitySystemsNotInIamEntity.processedBy.login
                  : ''}
              </dd>
              <dt>
                <span id="comments">
                  <Translate contentKey="iamdentityApp.reconSessionUserIdentitySystemsNotInIam.comments">Comments</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserIdentitySystemsNotInIamEntity.comments}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserIdentitySystemsNotInIam.inUserIdentity">In User Identity</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentitySystemsNotInIamEntity.inUserIdentity
                  ? reconSessionUserIdentitySystemsNotInIamEntity.inUserIdentity.userBusinessKey
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserIdentitySystemsNotInIam.importSession">Import Session</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentitySystemsNotInIamEntity.importSession
                  ? reconSessionUserIdentitySystemsNotInIamEntity.importSession.id
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentitySystemsNotInIamEntity.createdBy ? reconSessionUserIdentitySystemsNotInIamEntity.createdBy : ''}
              </dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentitySystemsNotInIamEntity.createdDate ? (
                  <TextFormat
                    value={reconSessionUserIdentitySystemsNotInIamEntity.createdDate}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentitySystemsNotInIamEntity.lastModifiedBy
                  ? reconSessionUserIdentitySystemsNotInIamEntity.lastModifiedBy
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentitySystemsNotInIamEntity.lastModifiedDate ? (
                  <TextFormat
                    value={reconSessionUserIdentitySystemsNotInIamEntity.lastModifiedDate}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" color="info" onClick={navigateBack} data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            <Button
              tag={Link}
              to={`/recon-session-user-identity-systems-not-in-iam/${reconSessionUserIdentitySystemsNotInIamEntity.id}/edit`}
              replace
              color="primary"
            >
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ReconSessionUserIdentitySystemsNotInIamDetail;
