import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './profile-user-identity-role.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const ProfileUserIdentityRoleDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { profileUserIdentityRoleId: id } = useParams<'profileUserIdentityRoleId'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PROFILE_USER_IDENTITY_ROLE));
  }, []);

  const profileUserIdentityRoleEntity = useAppSelector(state => state.profileUserIdentityRole.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card card-grey p-2">
            <h2 data-cy="profileUserIdentityRoleDetailsHeading">
              <Translate contentKey="iamdentityApp.profileUserIdentityRole.detail.title">ProfileUserIdentityRole</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{profileUserIdentityRoleEntity.id}</dd>
              <dt>
                <span id="descriptionExpression">
                  <Translate contentKey="iamdentityApp.profileUserIdentityRole.descriptionExpression">Description Expression</Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityRoleEntity.descriptionExpression ? (
                  <CompactExpressionBuilder tree={profileUserIdentityRoleEntity.descriptionExpression} />
                ) : null}
              </dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.profileUserIdentityRole.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityRoleEntity.startDate ? (
                  <TextFormat value={profileUserIdentityRoleEntity.startDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.profileUserIdentityRole.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityRoleEntity.endDate ? (
                  <TextFormat value={profileUserIdentityRoleEntity.endDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="roleDefinitionExpression">
                  <Translate contentKey="iamdentityApp.profileUserIdentityRole.roleDefinitionExpression">
                    Role Definition Expression
                  </Translate>
                </span>
              </dt>
              <dd>
                {profileUserIdentityRoleEntity.roleDefinitionExpression ? (
                  <CompactExpressionBuilder tree={profileUserIdentityRoleEntity.roleDefinitionExpression} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.profileUserIdentityRole.profileUserIdentity">Profile User Identity</Translate>
              </dt>
              <dd>{profileUserIdentityRoleEntity.profileUserIdentity ? profileUserIdentityRoleEntity.profileUserIdentity.id : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.profileUserIdentityRole.roleDefinition">Role Definition</Translate>
              </dt>
              <dd>{profileUserIdentityRoleEntity.roleDefinition ? profileUserIdentityRoleEntity.roleDefinition.name : ''}</dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment key={cad.id}>
                  {foundCustomAttributeID(cad, profileUserIdentityRoleEntity, locale) && (
                    <>
                      <dt>
                        <span id={cad.displayName}>{cad.displayName}</span>
                      </dt>
                      <dd>{findCustomAttributeValueByKey(cad, profileUserIdentityRoleEntity, locale)}</dd>
                    </>
                  )}
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{profileUserIdentityRoleEntity.createdBy ? profileUserIdentityRoleEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {profileUserIdentityRoleEntity.createdDate ? (
                  <TextFormat value={profileUserIdentityRoleEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{profileUserIdentityRoleEntity.lastModifiedBy ? profileUserIdentityRoleEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {profileUserIdentityRoleEntity.lastModifiedDate ? (
                  <TextFormat
                    value={profileUserIdentityRoleEntity.lastModifiedDate}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Card>
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.PROFILE_USER_IDENTITY_ROLE} />
    </React.Fragment>
  );
};

export default ProfileUserIdentityRoleDetail;
