import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { DropdownItem } from 'reactstrap';
import MenuItem from './menu-item';
import './nav-dropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

export const HrMenuData = () => {
  const location = useLocation(); // Obținem locația curentă

  // Lista de rute pentru submeniuri
  const subMenuRoutes = [
    '/in-employee',
    '/in-organization-unit',
    '/in-location',
    '/in-employee-delegation',
    '/in-project',
    '/in-project-assignment',
    '/in-job-definition',
    '/in-additional-attribute',
    '/employee',
    '/employee-delegation',
    '/person',
    '/organization-unit',
    '/job-definition',
    '/location',
    '/organization-unit-type',
    '/project',
    '/project-assignment',
    '/iam-imports',
  ];

  // Lista de rute pentru submeniuri
  const subMenuRoutesIN = [
    '/in-employee',
    '/in-organization-unit',
    '/in-location',
    '/in-employee-delegation',
    '/in-project',
    '/in-project-assignment',
    '/in-job-definition',
    '/in-additional-attribute',
  ];

  // Verificăm dacă vreuna dintre rutele de submeniu este activă
  const isSubMenuActive = subMenuRoutes.some(route => location.pathname.includes(route));
  const isSubMenuActiveIN = subMenuRoutesIN.some(route => location.pathname.includes(route));

  return (
    <NavDropdown
      isActive={isSubMenuActive}
      icon={faPeopleGroup}
      name={translate('global.menu.hrData')}
      id="entity-menu"
      data-cy="entity"
      className="max-height-80"
    >
      <DropdownItem className="menu-header" header>
        <Translate contentKey="global.menu.hrData" />
      </DropdownItem>
      <MenuItem icon="asterisk" to="/employee">
        <Translate contentKey="global.menu.entities.employee" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/employee-delegation">
        <Translate contentKey="global.menu.entities.employeeDelegation" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/person">
        <Translate contentKey="global.menu.entities.person" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/organization-unit">
        <Translate contentKey="global.menu.entities.organizationUnit" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/job-definition">
        <Translate contentKey="global.menu.entities.jobDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/location">
        <Translate contentKey="global.menu.entities.location" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/organization-unit-type">
        <Translate contentKey="global.menu.entities.organizationUnitType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project">
        <Translate contentKey="global.menu.entities.project" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project-assignment">
        <Translate contentKey="global.menu.entities.projectAssignment" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/iam-imports">
        <Translate contentKey="global.menu.entities.iamImports" />
      </MenuItem>
      <DropdownItem className="d-inline-block submenu dropdown-custom" toggle={false} tag="span">
        <NavDropdown
          isActive={isSubMenuActiveIN}
          icon="th-list"
          name={translate('global.menu.hrDataExt')}
          id="hr-data-external"
          data-cy="entity"
          direction="right"
          className="max-height-80 overflow-a bgc-custom"
        >
          <MenuItem icon="asterisk" to="/in-employee">
            <Translate contentKey="global.menu.entities.inEmployee" />
          </MenuItem>
          <MenuItem icon="asterisk" to="/in-organization-unit">
            <Translate contentKey="global.menu.entities.inOrganizationUnit" />
          </MenuItem>
          <MenuItem icon="asterisk" to="/in-location">
            <Translate contentKey="global.menu.entities.inLocation" />
          </MenuItem>
          <MenuItem icon="asterisk" to="/in-employee-delegation">
            <Translate contentKey="global.menu.entities.inEmployeeDelegation" />
          </MenuItem>
          <MenuItem icon="asterisk" to="/in-project">
            <Translate contentKey="global.menu.entities.inProject" />
          </MenuItem>
          <MenuItem icon="asterisk" to="/in-project-assignment">
            <Translate contentKey="global.menu.entities.inProjectAssignment" />
          </MenuItem>
          <MenuItem icon="asterisk" to="/in-job-definition">
            <Translate contentKey="global.menu.entities.inJobDefinition" />
          </MenuItem>
          <MenuItem icon="asterisk" to="/in-additional-attribute">
            <Translate contentKey="global.menu.entities.inAdditionalAttribute" />
          </MenuItem>
        </NavDropdown>
      </DropdownItem>
    </NavDropdown>
  );
};
