import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import IAMImports from './iam-imports';
import IAMImportsDetail from './iam-imports-detail';
import IAMImportsUpdate from './iam-imports-update';
import IAMImportsDeleteDialog from './iam-imports-delete-dialog';

const IAMImportsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<IAMImports />} />
    <Route path="new" element={<IAMImportsUpdate />} />
    <Route path=":id">
      <Route index element={<IAMImportsDetail />} />
      <Route path="edit" element={<IAMImportsUpdate />} />
      <Route path="delete" element={<IAMImportsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default IAMImportsRoutes;
