import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateFormat, convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { apiUrl as applicationsUrl } from 'app/entities/application/application.reducer';
import { apiUrl as employeesUrl } from 'app/entities/employee/employee.reducer';
import { apiUrl as ordersUrl } from 'app/entities/orders/orders.reducer';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { OperationType } from 'app/shared/model/enumerations/operation-type.model';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IOrderedUserIdentity } from 'app/shared/model/ordered-user-identity.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import { Controller, useForm } from 'react-hook-form';
import { createEntity, getEntity, resetEntity, updateEntity } from './ordered-user-identity.reducer';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ro from 'date-fns/locale/ro';
import { toast } from 'react-toastify';
import ValidatedInput from 'app/shared/components/validated-input';
import { is_valid_email } from 'app/shared/util/email';
import navigateBack from 'app/shared/components/handlers/buttons/back';

interface ISearchParamsObject {
  application?: any;
  employee?: any;
  order?: { id: number; scheduleTime: any };
  operationType?: OperationType;
  grantedBy?: PermissionGrantLevel;
  executionStatus?: any;
}

const USER_DEPENDS_ON_EXISTING_LIST_URL = 'api/order-create/dependsOn-user-identity-list';
const USER_DEPENDS_ON_LIST_URL = 'api/order-create/dependsOn-ordered-user-identity-list';

enum DateNextChangePasswordOptions {
  inADay = 'inADay',
  inAWeek = 'inAWeek',
  inAMonth = 'inAMonth',
  after3Months = 'after3Months',
  after6Months = 'after6Months',
  inAYear = 'inAYear',
}

function calculateDateNextChangePassword(value: DateNextChangePasswordOptions) {
  switch (value) {
    case DateNextChangePasswordOptions.inADay:
      return moment().add(1, 'days');
    case DateNextChangePasswordOptions.inAWeek:
      return moment().add(1, 'weeks');
    case DateNextChangePasswordOptions.inAMonth:
      return moment().add(1, 'months');
    case DateNextChangePasswordOptions.after3Months:
      return moment().add(3, 'months');
    case DateNextChangePasswordOptions.after6Months:
      return moment().add(6, 'months');
    case DateNextChangePasswordOptions.inAYear:
      return moment().add(1, 'years');
    default:
      return moment().add(1, 'days');
  }
}

export const OrderedUserIdentityUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const userIdentities = useAppSelector(state => state.userIdentity.entities);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));
  const { orderedUserIdentityId: id } = useParams<'orderedUserIdentityId'>();
  const isNew = id === undefined;

  const orderedUserIdentityEntity: IOrderedUserIdentity = useAppSelector(state => state.orderedUserIdentity.entity);
  const loading = useAppSelector(state => state.orderedUserIdentity.loading);
  const updating = useAppSelector(state => state.orderedUserIdentity.updating);
  const updateSuccess = useAppSelector(state => state.orderedUserIdentity.updateSuccess);
  const permissionGrantLevelValues = Object.values(PermissionGrantLevel);
  const orderExecutionStatusValues = Object.values(OrderExecutionStatus);
  const operationTypeValues = Object.values(OperationType);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORDERED_USER_IDENTITY));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      dispatch(resetEntity());
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [orderedUserIdentityEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    if (!values?.userName) {
      toast.error(translate('iamdentityApp.orderedUserIdentity.userName') + ' - ' + translate('entity.validation.required'));
      return;
    }
    if (watchApplication?.userDependsOnApplication && !values?.dependsOn && !values.dependsOnExisting) {
      toast.error(translate('iamdentityApp.orderedUserIdentity.dependsMessage'));
      return;
    }

    values.scheduleTime = convertDateTimeToServer(isNew ? values?.order?.scheduleTime : values.scheduleTime);
    values.executionStartTime = convertDateTimeToServer(values.executionStartTime);
    values.executionEndTime = convertDateTimeToServer(values.executionEndTime);
    values.businessValidityStart = convertDateTimeToServer(isNew ? values?.order?.scheduleTime : values.businessValidityStart);
    values.businessValidityEnd = convertDateTimeToServer(isNew ? values?.order?.orderValidUntil : values.businessValidityEnd);
    values.returnedBusinessValidityStart = convertDateTimeToServer(values.returnedBusinessValidityStart);
    values.returnedBusinessValidityEnd = convertDateTimeToServer(values.returnedBusinessValidityEnd);
    values.dateNextChangePassword = isNew
      ? values?.dateNextChangePassword
        ? calculateDateNextChangePassword(values?.dateNextChangePassword).format(APP_LOCAL_DATETIME_FORMAT)
        : null
      : values?.dateNextChangePassword;

    const entity = {
      ...orderedUserIdentityEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        orderedUserIdentityEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          // scheduleTime: displayDefaultDateTime(),
          executionStartTime: displayDefaultDateTime(),
          executionEndTime: displayDefaultDateTime(),
          // businessValidityStart: displayDefaultDateTime(),
          // businessValidityEnd: displayDefaultDateTime(),
          returnedBusinessValidityStart: displayDefaultDateTime(),
          returnedBusinessValidityEnd: displayDefaultDateTime(),
          executionStatus: OrderExecutionStatus.NOT_STARTED,
          ...searchParamsObject,
        }
      : {
          ...orderedUserIdentityEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, orderedUserIdentityEntity),
          grantedBy: orderedUserIdentityEntity.grantedBy,
          executionStatus: orderedUserIdentityEntity.executionStatus,
          scheduleTime: convertDateTimeFromServer(orderedUserIdentityEntity.scheduleTime),
          executionStartTime: convertDateTimeFromServer(orderedUserIdentityEntity.executionStartTime),
          executionEndTime: convertDateTimeFromServer(orderedUserIdentityEntity.executionEndTime),
          businessValidityStart: convertDateTimeFromServer(orderedUserIdentityEntity.businessValidityStart),
          businessValidityEnd: convertDateTimeFromServer(orderedUserIdentityEntity.businessValidityEnd),
          returnedBusinessValidityStart: convertDateTimeFromServer(orderedUserIdentityEntity.returnedBusinessValidityStart),
          returnedBusinessValidityEnd: convertDateTimeFromServer(orderedUserIdentityEntity.returnedBusinessValidityEnd),
          order: orderedUserIdentityEntity?.order,
          application: orderedUserIdentityEntity?.application,
          employee: orderedUserIdentityEntity?.employee,
          dependsOn: orderedUserIdentityEntity?.dependsOn,
          operationType: orderedUserIdentityEntity.operationType,
          retrieveUserNameFromDependentOrderedUserIdentity: orderedUserIdentityEntity?.retrieveUserNameFromDependentOrderedUserIdentity,
          ...searchParamsObject,
        };

  const {
    register,
    handleSubmit,
    control,
    watch,
    resetField,
    setValue,
    reset: formReset,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const { ref: retrieveUserNameFromDependentOrderedUserIdentityRef, ...retrieveUserNameFromDependentOrderedUserIdentityProps } = register(
    'retrieveUserNameFromDependentOrderedUserIdentity'
  );
  const { ref: dependsOnRef, ...dependsOnProps } = register('dependsOn');
  const { ref: dependsOnExistingRef, ...dependsOnExistingProps } = register('dependsOnExisting');
  const { ref: usernameRef, ...usernameProps } = register('userName');
  const { ref: dateNextChangePasswordRef, ...dateNextChangePasswordProps } = register('dateNextChangePassword');

  const watchBusinessValidityStart = watch('businessValidityStart');
  const watchBusinessValidityEnd = watch('businessValidityEnd');
  const watchApplication = watch('application');
  const watchRetrieveUserName = watch('retrieveUserNameFromDependentOrderedUserIdentity');
  const watchDependsOn = watch('dependsOn');
  const watchDependsOnExisting = watch('dependsOnExisting');

  useEffect(() => {
    if ((isNew && !watchApplication) || (isNew && watchApplication)) {
      setValue('retrieveUserNameFromDependentOrderedUserIdentity', false);
      setValue('dependsOn', null);
      setValue('dependsOnExisting', null);
      setValue('userName', null);
    }
  }, [isNew, watchApplication, setValue]);

  useEffect(() => {
    if (isNew) {
      if (watchApplication?.userDependsOnApplication) {
        setValue('retrieveUserNameFromDependentOrderedUserIdentity', true);
      } else {
        setValue('retrieveUserNameFromDependentOrderedUserIdentity', false);
      }
    }
  }, [isNew, watchApplication, setValue]);

  useEffect(() => {
    if (watchDependsOn) {
      setValue('dependsOnExisting', null);
    }
  }, [watchDependsOn, setValue]);

  useEffect(() => {
    if (watchDependsOnExisting) {
      setValue('dependsOn', null);
    }
  }, [watchDependsOnExisting, setValue]);

  useEffect(() => {
    if (watchRetrieveUserName == true) {
      if (watchDependsOnExisting) {
        setValue('userName', watchDependsOnExisting?.userName);
      } else if (watchDependsOn) {
        setValue('userName', watchDependsOn?.userName);
      } else {
        setValue('userName', null);
      }
    } else {
      setValue('userName', orderedUserIdentityEntity?.userName ? orderedUserIdentityEntity?.userName : null);
    }
  }, [watchRetrieveUserName, watchDependsOn, watchDependsOnExisting]);

  const selectDependsOnSource = (orderId?: number, applicationId?: number, ouiId?: any) => {
    return USER_DEPENDS_ON_LIST_URL + '?orderId=' + orderId + '&applicationId=' + applicationId + '&ouiId=' + ouiId;
  };

  const selectDependsOnExistingSource = (employeeId?: number, applicationId?: number, scheduleTime: any = null) => {
    if (scheduleTime) {
      const event = new Date(scheduleTime);
      return (
        USER_DEPENDS_ON_EXISTING_LIST_URL +
        '?employeeId=' +
        employeeId +
        '&applicationId=' +
        applicationId +
        '&scheduleTime=' +
        event.toISOString()
      );
    } else {
      return USER_DEPENDS_ON_EXISTING_LIST_URL + '?employeeId=' + employeeId + '&applicationId=' + applicationId + '&scheduleTime=' + '';
    }
  };

  return (
    <Card className="jh-card pad-1">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.orderedUserIdentity.home.createOrEditLabel" data-cy="OrderedUserIdentityCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.orderedUserIdentity.home.createOrEditLabel">
              Create or edit a OrderedUserIdentity
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="ordered-user-identity-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <FormGroup>
                <Label>
                  *
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.application" />
                </Label>
                <PaginatedReactSelect
                  validate={{ required: true }}
                  control={control}
                  name="application"
                  url={applicationsUrl}
                  isDisabled={!isNew ? true : false}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.retrieveUserNameFromDependentOrderedUserIdentity')}
                id="ordered-user-identity-retrieveUserNameFromDependentOrderedUserIdentity"
                name="retrieveUserNameFromDependentOrderedUserIdentity"
                data-cy="retrieveUserNameFromDependentOrderedUserIdentity"
                check
                type="checkbox"
                {...retrieveUserNameFromDependentOrderedUserIdentityProps}
                innerRef={retrieveUserNameFromDependentOrderedUserIdentityRef}
                className="mb-3"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.dependsOn" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="dependsOn"
                  isPageable={false}
                  url={
                    isNew
                      ? searchParamsObject?.order?.id && watchApplication?.id
                        ? selectDependsOnSource(searchParamsObject?.order?.id, watchApplication?.id, '')
                        : ''
                      : orderedUserIdentityEntity?.order?.id && watchApplication?.id && orderedUserIdentityEntity?.id
                      ? selectDependsOnSource(orderedUserIdentityEntity?.order?.id, watchApplication?.id, orderedUserIdentityEntity?.id)
                      : ''
                  }
                  queryProps={{
                    name: 'userName',
                    secondName: 'application.name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.dependsOnExisting" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="dependsOnExisting"
                  isPageable={false}
                  url={
                    isNew
                      ? searchParamsObject?.employee?.id && watchApplication?.id && searchParamsObject?.order?.scheduleTime
                        ? selectDependsOnExistingSource(
                            searchParamsObject?.employee?.id,
                            watchApplication?.id,
                            searchParamsObject?.order?.scheduleTime
                          )
                        : ''
                      : orderedUserIdentityEntity?.employee?.id && watchApplication?.id && orderedUserIdentityEntity?.scheduleTime
                      ? selectDependsOnExistingSource(
                          orderedUserIdentityEntity?.employee?.id,
                          watchApplication?.id,
                          orderedUserIdentityEntity?.scheduleTime
                        )
                      : ''
                  }
                  queryProps={{
                    name: 'userName',
                    secondName: 'application.name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                disabled={watchRetrieveUserName == true ? true : false}
                {...usernameProps}
                innerRef={usernameRef}
                register={register}
                label={'*' + translate('iamdentityApp.orderedUserIdentity.userName')}
                id="ordered-user-identity-userName"
                name="userName"
                data-cy="userName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.initialPassword')}
                id="ordered-user-identity-initialPassword"
                name="initialPassword"
                data-cy="initialPassword"
                type="password"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.alternativeUserName')}
                id="ordered-user-identity-alternativeUserName"
                name="alternativeUserName"
                data-cy="alternativeUserName"
                type="text"
              />
              <ValidatedInput
                control={control}
                label={translate('iamdentityApp.orderedUserIdentity.associatedEmailAddress')}
                name="associatedEmailAddress"
                id="ordered-user-identity-associatedEmailAddress"
                data-cy="associatedEmailAddress"
                type="text"
                validate={{
                  validate(value) {
                    if (!value) {
                      return true;
                    }
                    return is_valid_email(value) ? true : translate('entity.validation.emailNotValid');
                  },
                }}
              />
              {errors.associatedEmailAddress?.message && (
                <span style={{ marginTop: '-1.8rem', marginBottom: '1.5rem' }} className="invalid-feedback d-block">
                  {typeof errors.associatedEmailAddress.message === 'string' && errors.associatedEmailAddress.message}
                </span>
              )}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.displayName')}
                id="ordered-user-identity-displayName"
                name="displayName"
                data-cy="displayName"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.mustChangePasswordOnFirstLogin')}
                id="ordered-user-identity-mustChangePasswordOnFirstLogin"
                name="mustChangePasswordOnFirstLogin"
                data-cy="mustChangePasswordOnFirstLogin"
                check
                type="checkbox"
                className="mb-3"
              />
              {isNew ? (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.dateNextChangePassword" />
                  </Label>
                  <Input
                    valid={touchedFields.dateNextChangePassword && !errors.dateNextChangePassword}
                    invalid={!!errors.dateNextChangePassword}
                    type="select"
                    {...dateNextChangePasswordProps}
                    innerRef={dateNextChangePasswordRef}
                  >
                    <option value="" key="0">
                      {translate('global.select')}
                    </option>
                    {Object.values(DateNextChangePasswordOptions).map(val => (
                      <option value={val} key="0">
                        <Translate contentKey={`iamdentityApp.orderedUserIdentity.dateNextChangePasswordOptions.${val}`} />
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              ) : (
                <div className="date-picker-container mb-3">
                  <Controller
                    control={control}
                    name="dateNextChangePassword"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <div className="form-group">
                        <label htmlFor="ordered-user-identity-dateNextChangePassword" className="form-label">
                          {translate('iamdentityApp.orderedUserIdentity.dateNextChangePassword')}
                        </label>
                        <DatePicker
                          maxDate={watchBusinessValidityEnd ? moment(watchBusinessValidityEnd, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="ordered-user-identity-dateNextChangePassword"
                          data-cy="dateNextChangePassword"
                          disabled
                        />
                      </div>
                    )}
                  />
                </div>
              )}
              <ValidatedField
                register={register}
                disabled={!!searchParamsObject?.grantedBy || !isNew}
                readOnly
                label={translate('iamdentityApp.orderedUserIdentity.grantedBy')}
                id="ordered-user-identity-grantedBy"
                name="grantedBy"
                data-cy="grantedBy"
                type="select"
              >
                {permissionGrantLevelValues.map(permissionGrantLevel => (
                  <option value={permissionGrantLevel} key={permissionGrantLevel}>
                    {translate('iamdentityApp.PermissionGrantLevel.' + permissionGrantLevel)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.executionStatus')}
                id="ordered-user-identity-executionStatus"
                name="executionStatus"
                data-cy="executionStatus"
                type="select"
                disabled={true}
              >
                {orderExecutionStatusValues.map(orderExecutionStatus => (
                  <option value={orderExecutionStatus} key={orderExecutionStatus}>
                    {translate('iamdentityApp.OrderExecutionStatus.' + orderExecutionStatus)}
                  </option>
                ))}
              </ValidatedField>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="scheduleTime"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="ordered-user-identity-scheduleTime" className="form-label">
                        {translate('iamdentityApp.orderedUserIdentity.scheduleTime')}
                      </label>
                      <DatePicker
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'instant')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="ordered-user-identity-scheduleTime"
                        data-cy="scheduleTime"
                        disabled
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="businessValidityStart"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="ordered-user-identity-businessValidityStart" className="form-label">
                        {translate('iamdentityApp.orderedUserIdentity.businessValidityStart')}
                      </label>
                      <DatePicker
                        maxDate={watchBusinessValidityEnd ? moment(watchBusinessValidityEnd, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'instant')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="ordered-user-identity-businessValidityStart"
                        data-cy="businessValidityStart"
                        disabled
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="businessValidityEnd"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="ordered-user-identity-businessValidityEnd" className="form-label">
                        {translate('iamdentityApp.orderedUserIdentity.businessValidityEnd')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchBusinessValidityStart ? moment(watchBusinessValidityStart, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'instant')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'instant')) : convertDateFormat(locale, 'instant')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="ordered-user-identity-businessValidityEnd"
                          data-cy="businessValidityEnd"
                          disabled
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.orders" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="order"
                  url={ordersUrl}
                  isDisabled={!!searchParamsObject?.order}
                  queryProps={{
                    name: 'id',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.employee" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  isDisabled
                  name="employee"
                  url={employeesUrl}
                  queryProps={{
                    name: 'code',
                    secondName: 'fullName',
                    type: 'string',
                    criteria: 'nameWithCode',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.orderedUserIdentity.operationType')}
                id="ordered-user-identity-operationType"
                disabled={!!searchParamsObject?.operationType || !isNew}
                name="operationType"
                data-cy="operationType"
                type="select"
              >
                {operationTypeValues.map(operationType => (
                  <option value={operationType} key={operationType}>
                    {translate('iamdentityApp.OperationType.' + operationType)}
                  </option>
                ))}
              </ValidatedField>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  control: control,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `orderedUserIdentity-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default OrderedUserIdentityUpdate;
