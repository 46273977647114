import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Spinner, Form } from 'reactstrap';

import ValidatedInput from 'app/shared/components/validated-input';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getEntitiesByObjectType as getCustomPropertiesByObjectType,
  reset as resetCA,
} from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { addCustomPropertiesToCustomAttributesMap, extractCustomAttributesAsEntityProperties } from 'app/shared/util/entity-utils';
import { useForm } from 'react-hook-form';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { createEntity, getEntity, reset, updateEntity } from './job-definition.reducer';
import moment from 'moment';
import { useRef } from 'react';
import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import navigateBack from 'app/shared/components/handlers/buttons/back';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getApiConfigurations } from 'app/modules/administration/administration.reducer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const JobDefinitionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const jobDefinitionEntity = useAppSelector(state => state.jobDefinition.entity);
  const loading = useAppSelector(state => state.jobDefinition.loading);
  const updating = useAppSelector(state => state.jobDefinition.updating);
  const updateSuccess = useAppSelector(state => state.jobDefinition.updateSuccess);
  const configuration = useAppSelector(state => state.administration.configuration);
  const allowManualEntityCreationProp = configuration?.apiConfigProps?.allowManualEntityCreation;
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isHRAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.HR_ADMIN]));

  useEffect(() => {
    dispatch(getApiConfigurations());
  }, []);

  useEffect(() => {
    if (allowManualEntityCreationProp !== undefined && (!(isAdmin || isHRAdmin) || !allowManualEntityCreationProp)) {
      toast.error(translate('error.unauthorizedMessage'));
      navigate('/');
    }
  }, [isAdmin, isHRAdmin, allowManualEntityCreationProp, navigate]);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    dispatch(resetCA());
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.JOB_DEFINITION));
  }, [id]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [jobDefinitionEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(jobDefinitionEntity, values, customAttributeDefinitions),
      startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : null, // Format startDate for server
      endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null, // Format endDate for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: moment(new Date(), convertDateFormat(locale, 'date')).toDate(),
          endDate: null,
        }
      : {
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, jobDefinitionEntity),
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.jobDefinition.home.createOrEditLabel" data-cy="JobDefinitionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.jobDefinition.home.createOrEditLabel">Create or edit a JobDefinition</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="job-definition-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.jobDefinition.name')}
                name="name"
                id="job-definition-name"
                data-cy="name"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.jobDefinition.internalCode')}
                name="internalCode"
                id="job-definition-internalCode"
                data-cy="internalCode"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.jobDefinition.publicCode')}
                id="job-definition-publicCode"
                name="publicCode"
                data-cy="publicCode"
                type="text"
              />
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="job-definition-startDate" className="form-label">
                        {translate('iamdentityApp.jobDefinition.startDate')}
                      </label>
                      <DatePicker
                        maxDate={watchEndDate ? moment(watchEndDate, 'YYYY-MM-DD').toDate() : null}
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="job-definition-startDate"
                        data-cy="startDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="job-definition-endDate" className="form-label">
                        {translate('iamdentityApp.jobDefinition.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={watchStartDate ? moment(watchStartDate, 'YYYY-MM-DD').toDate() : null}
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="job-definition-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  options: cad.selectableOptions,
                  id: `job-definition-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={navigateBack} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default JobDefinitionUpdate;
