import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, APP_TABLE_TEXT_MAX_CHARS } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';
import { exportEntities, fetchEntityList, searchEntities } from './ordered-user-identity.reducer';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Filter from 'app/shared/FilterComponent';
import { OperationType } from 'app/shared/model/enumerations/operation-type.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';

import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import {
  findCustomAttributeValueByKey,
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import ShowCustomAttributes from 'app/shared/components/customButtons/showCustomAttributes';
import CustomText from 'app/shared/components/customText/customText';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const OrderedUserIdentity = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));
  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      fetchEntityList({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const orderedUserIdentityList = useAppSelector(state => state.orderedUserIdentity.entities);
  const loading = useAppSelector(state => state.orderedUserIdentity.loading || state.customAttributeDefinition.loading);
  const totalItems = useAppSelector(state => state.orderedUserIdentity.totalItems);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state =>
    state.customAttributeDefinition.entities?.filter(() => localStorage.getItem('TABLE_showCustomAttributes') === 'true')
  );
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };
  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    } else {
      dispatch(
        fetchEntityList({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: search,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(fetchEntityList({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };
  useEffect(() => {
    const query = getQueryParamsIfExists(location.search);
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.ORDERED_USER_IDENTITY));
    if (query) {
      setQuery(query);
    }
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="ordered-user-identity-heading" data-cy="OrderedUserIdentityHeading">
        <Translate contentKey="iamdentityApp.orderedUserIdentity.home.title">Ordered User Identities</Translate>
        <div className="d-flex justify-content-end">
          <ShowCustomAttributes />
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            <Translate contentKey="iamdentityApp.orderedUserIdentity.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom table-input-number">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderedUserIdentity.id'),
                  },
                  {
                    name: 'orderId',
                    type: 'number',
                    placeholder: translate('iamdentityApp.orderedUserIdentity.orderId'),
                  },
                  {
                    name: 'application',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderedUserIdentity.application'),
                  },
                  {
                    name: 'employee',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderedUserIdentity.employee'),
                  },
                  {
                    name: 'operationType',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(OperationType).map(value => ({
                        value,
                        label: translate(`iamdentityApp.OperationType.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'userName',
                    type: 'string',
                    placeholder: translate('iamdentityApp.orderedUserIdentity.userName'),
                  },
                  {
                    name: 'grantedBy',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(PermissionGrantLevel).map(value => ({
                        value,
                        label: translate(`iamdentityApp.PermissionGrantLevel.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'executionStatus',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(OrderExecutionStatus).map(value => ({
                        value,
                        label: translate(`iamdentityApp.OrderExecutionStatus.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'scheduleTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionStartTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionEndTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionActualDuration',
                    type: 'number',
                    placeholder: translate('iamdentityApp.orderedUserIdentity.executionActualDuration'),
                  },
                  {
                    name: 'businessValidityStart',
                    type: 'instant',
                  },
                  {
                    name: 'businessValidityEnd',
                    type: 'instant',
                  },
                  {
                    name: 'returnedBusinessValidityStart',
                    type: 'instant',
                  },
                  {
                    name: 'returnedBusinessValidityEnd',
                    type: 'instant',
                  },
                  ...getFiltersForCustomAttributes(customAttributeDefinitions),
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('order')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.orders">Orders</Translate>
                  </CustomText>
                  {handleSortIconsTable('order')}
                </div>
              </th>
              <th className="hand" onClick={sort('application.name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.application">Application</Translate>
                  </CustomText>
                  {handleSortIconsTable('application.name')}
                </div>
              </th>
              <th className="hand" onClick={sort('employee.code')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.employee">Employee</Translate>
                  </CustomText>
                  {handleSortIconsTable('employee.code')}
                </div>
              </th>
              <th className="hand" onClick={sort('operationType')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.operationType">Operation Type</Translate>
                  </CustomText>
                  {handleSortIconsTable('operationType')}
                </div>
              </th>
              <th className="hand" onClick={sort('userName')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.userName">User Name</Translate>
                  </CustomText>
                  {handleSortIconsTable('userName')}
                </div>
              </th>
              <th className="hand" onClick={sort('grantedBy')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.grantedBy">Granted By</Translate>
                  </CustomText>
                  {handleSortIconsTable('grantedBy')}
                </div>
              </th>
              <th className="hand" onClick={sort('executionStatus')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.executionStatus">Execution Status</Translate>
                  </CustomText>
                  {handleSortIconsTable('executionStatus')}
                </div>
              </th>
              <th className="hand" onClick={sort('scheduleTime')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.scheduleTime">Schedule Time</Translate>
                  </CustomText>
                  {handleSortIconsTable('scheduleTime')}
                </div>
              </th>
              <th className="hand" onClick={sort('executionStartTime')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.executionStartTime">Execution Start Time</Translate>
                  </CustomText>
                  {handleSortIconsTable('executionStartTime')}
                </div>
              </th>
              <th className="hand" onClick={sort('executionEndTime')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.executionEndTime">Execution End Time</Translate>
                  </CustomText>
                  {handleSortIconsTable('executionEndTime')}
                </div>
              </th>
              <th className="hand" onClick={sort('executionActualDuration')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.executionActualDuration">Execution Actual Duration</Translate>
                  </CustomText>
                  {handleSortIconsTable('executionActualDuration')}
                </div>
              </th>
              <th className="hand" onClick={sort('businessValidityStart')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.businessValidityStart">Business Validity Start</Translate>
                  </CustomText>
                  {handleSortIconsTable('businessValidityStart')}
                </div>
              </th>
              <th className="hand" onClick={sort('businessValidityEnd')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.businessValidityEnd">Business Validity End</Translate>
                  </CustomText>
                  {handleSortIconsTable('businessValidityEnd')}
                </div>
              </th>
              <th className="hand" onClick={sort('returnedBusinessValidityStart')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedBusinessValidityStart">
                      Returned Business Validity Start
                    </Translate>
                  </CustomText>
                  {handleSortIconsTable('returnedBusinessValidityStart')}
                </div>
              </th>
              <th className="hand" onClick={sort('returnedBusinessValidityEnd')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedBusinessValidityEnd">
                      Returned Business Validity End
                    </Translate>
                  </CustomText>
                  {handleSortIconsTable('returnedBusinessValidityEnd')}
                </div>
              </th>
              {customAttributeDefinitions?.map(cad => (
                <th key={cad.id} className={'hand'}>
                  <div className={'d-flex-filter'}>
                    <CustomText>{cad.displayName}</CustomText>
                  </div>
                </th>
              ))}
              <th className="hand">
                {/*  <span>*/}
                {/*    <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'}*/}
                {/*            onClick={toggleFilter}>*/}
                {/*      <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*    </Button>*/}
                {/*  </span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide*/}
                {/*    Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {orderedUserIdentityList.map((orderedUserIdentity, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Link to={`/ordered-user-identity/${orderedUserIdentity.id}`} color="link">
                    {orderedUserIdentity.id}
                  </Link>
                </td>
                <td>
                  {orderedUserIdentity.order ? (
                    <Link to={`/orders/${orderedUserIdentity.order.id}`}>{orderedUserIdentity.order.id}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {orderedUserIdentity.application ? (
                    <Link to={`/application/${orderedUserIdentity.application.id}`}>
                      <CustomText>{orderedUserIdentity.application.name}</CustomText>
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {orderedUserIdentity.employee ? (
                    <Link to={`/employee/${orderedUserIdentity.employee.id}`}>{orderedUserIdentity.employee.code}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.OperationType.${orderedUserIdentity.operationType}`)}</CustomText>
                </td>
                <td>
                  <CustomText>{orderedUserIdentity.userName}</CustomText>
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.PermissionGrantLevel.${orderedUserIdentity.grantedBy}`)}</CustomText>
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.OrderExecutionStatus.${orderedUserIdentity.executionStatus}`)}</CustomText>
                </td>
                <td>
                  {orderedUserIdentity.scheduleTime ? (
                    <TextFormat type="date" value={orderedUserIdentity.scheduleTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orderedUserIdentity.executionStartTime ? (
                    <TextFormat type="date" value={orderedUserIdentity.executionStartTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orderedUserIdentity.executionEndTime ? (
                    <TextFormat type="date" value={orderedUserIdentity.executionEndTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  <CustomText>{orderedUserIdentity.executionActualDuration}</CustomText>
                </td>
                <td>
                  {orderedUserIdentity.businessValidityStart ? (
                    <TextFormat
                      type="date"
                      value={orderedUserIdentity.businessValidityStart}
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : null}
                </td>
                <td>
                  {orderedUserIdentity.businessValidityEnd ? (
                    <TextFormat type="date" value={orderedUserIdentity.businessValidityEnd} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {orderedUserIdentity.returnedBusinessValidityStart ? (
                    <TextFormat
                      type="date"
                      value={orderedUserIdentity.returnedBusinessValidityStart}
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : null}
                </td>
                <td>
                  {orderedUserIdentity.returnedBusinessValidityEnd ? (
                    <TextFormat
                      type="date"
                      value={orderedUserIdentity.returnedBusinessValidityEnd}
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : null}
                </td>
                {customAttributeDefinitions?.map(cad => (
                  <td key={cad.id}>
                    <CustomText>{findCustomAttributeValueByKey(cad, orderedUserIdentity, locale)}</CustomText>
                  </td>
                ))}
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={`/ordered-user-identity/${orderedUserIdentity.id}`}
                      color="info"
                      size="sm"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />
                      {/*<span className="d-none d-md-inline">*/}
                      {/*  <Translate contentKey="entity.action.view">View</Translate>*/}
                      {/*</span>*/}
                    </Button>
                    {/* <Button
                      tag={Link}
                      to={`/ordered-user-identity/${orderedUserIdentity.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="warning"
                      size="sm"
                      data-cy="entityEditButton"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`/ordered-user-identity/${orderedUserIdentity.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="primary"
                      size="sm"
                      data-cy="entityDeleteButton"
                    >
                      <FontAwesomeIcon icon="trash" />
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span>
                    </Button> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!orderedUserIdentityList || orderedUserIdentityList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.orderedUserIdentity.home.notFound">No Ordered User Identities found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={orderedUserIdentityList && orderedUserIdentityList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.ORDERED_USER_IDENTITY}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'userName'}
        entityDetailsName={'orderedUserIdentity'}
        entityLink={'ordered-user-identity'}
      />
      <AuditModal
        isOpen={auditModal2}
        toggle={() => toggleAuditModal2()}
        entityId={entityId}
        entityName={AuditEntityName.ORDERED_USER_IDENTITY}
      />
    </Card>
  );
};

export default OrderedUserIdentity;
