import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './in-location.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { foundCustomAttributeID } from 'app/shared/util/entity-utils';

export const InLocationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inLocationEntity = useAppSelector(state => state.inLocation.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <Row>
      <Col md="3">
        <Card className="jh-card card-grey p-2">
          <h2 data-cy="inLocationDetailsHeading">
            <Translate contentKey="iamdentityApp.inLocation.detail.title">In Location</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="code">
                <Translate contentKey="iamdentityApp.inLocation.code">Code</Translate>
              </span>
            </dt>
            <dd>{inLocationEntity.code}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="iamdentityApp.inLocation.name">Name</Translate>
              </span>
            </dt>
            <dd>{inLocationEntity.name}</dd>
            <dt>
              <span id="validFrom">
                <Translate contentKey="iamdentityApp.inLocation.validFrom">Valid From</Translate>
              </span>
            </dt>
            <dd>
              {inLocationEntity.validFrom ? (
                <TextFormat value={inLocationEntity.validFrom} type="date" format={APP_LOCAL_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="validTo">
                <Translate contentKey="iamdentityApp.inLocation.validTo">Valid To</Translate>
              </span>
            </dt>
            <dd>
              {inLocationEntity.validTo ? <TextFormat value={inLocationEntity.validTo} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
            </dd>
            {customAttributeDefinitions?.map(cad => (
              <React.Fragment key={cad.id}>
                {foundCustomAttributeID(cad, inLocationEntity, locale) && (
                  <>
                    <dt>
                      <span id={cad.displayName}>{cad.displayName}</span>
                    </dt>
                    <dd>{findCustomAttributeValueByKey(cad, inLocationEntity, locale)}</dd>
                  </>
                )}
              </React.Fragment>
            ))}
          </dl>
          <Button style={{ width: 'fit-content' }} tag={Link} to="/in-location" replace color="info" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default InLocationDetail;
