import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, translate, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  findCustomAttributeValueByKey,
  getFiltersForCustomAttributes,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import Filter from 'app/shared/FilterComponent';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { exportEntities, getEntities, searchEntities } from './person.reducer';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';
import { APP_TABLE_TEXT_MAX_CHARS, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getApiConfigurations } from 'app/modules/administration/administration.reducer';
import CustomText from 'app/shared/components/customText/customText';
import ShowCustomAttributes from 'app/shared/components/customButtons/showCustomAttributes';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditEntityModal from '../audit/audit-entity-modal';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import AuditModal from '../audit/audit-modal';

export const Person = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const [auditModal2, setAuditModal2] = useState<boolean>(false);
  const [entityId, setEntityId] = useState<string>('');
  const [search, setSearch] = useState('');
  const configuration = useAppSelector(state => state.administration.configuration);
  const allowManualEntityCreationProp = configuration?.apiConfigProps?.allowManualEntityCreation;
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isHRAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.HR_ADMIN]));

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  useEffect(() => {
    dispatch(getApiConfigurations());
  }, []);

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const personList = useAppSelector(state => state.person.entities);
  const loading = useAppSelector(state => state.person.loading || state.customAttributeDefinition.loading);
  const totalItems = useAppSelector(state => state.person.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state =>
    state.customAttributeDefinition.entities?.filter(
      def => def.visibleInList && localStorage.getItem('TABLE_showCustomAttributes') === 'true'
    )
  );

  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PERSON));
  }, []);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  const toggleAuditModal2 = (id?: string) => {
    if (id) {
      setEntityId(id);
    }
    setAuditModal(false);
    setAuditModal2(!auditModal2);
  };

  return (
    <Card className="jh-card h-100">
      <h2 id="person-heading" data-cy="PersonHeading">
        <Translate contentKey="iamdentityApp.person.home.title">People</Translate>
        <div className="d-flex justify-content-end">
          <ShowCustomAttributes />
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />
            <Translate contentKey="iamdentityApp.person.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} />
            <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
          <Button onClick={toggleAuditModal} color="light" className="me-2">
            <FontAwesomeIcon icon={faClockRotateLeft} />
            <Translate contentKey="entity.action.history">History</Translate>
          </Button>
          {(isAdmin || isHRAdmin) && allowManualEntityCreationProp && (
            <Link to="/person/new" className="btn btn-success jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="plus" />
              <Translate contentKey="iamdentityApp.person.home.createLabel">Create new Person</Translate>
            </Link>
          )}
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom h-100">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'string',
                    placeholder: translate('iamdentityApp.person.id'),
                  },
                  {
                    name: 'name',
                    type: 'string',
                    placeholder: translate('iamdentityApp.person.name'),
                  },
                  {
                    name: 'firstName',
                    type: 'string',
                    placeholder: translate('iamdentityApp.person.firstName'),
                  },
                  {
                    name: 'nationalIdentifier',
                    type: 'string',
                    placeholder: translate('iamdentityApp.person.nationalIdentifier'),
                  },
                  {
                    name: 'gender',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      { value: 'MALE', label: translate(`iamdentityApp.Gender.MALE`) },
                      { value: 'FEMALE', label: translate(`iamdentityApp.Gender.FEMALE`) },
                    ],
                  },
                  {
                    name: 'phoneNumber',
                    type: 'string',
                    placeholder: translate('iamdentityApp.person.phoneNumber'),
                  },
                  {
                    name: 'communicationEmail',
                    type: 'string',
                    placeholder: translate('iamdentityApp.person.communicationEmail'),
                  },
                  {
                    name: 'dateOfBirth',
                    type: 'date',
                  },
                  {
                    name: 'nationality',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      { value: 'RO', label: translate(`iamdentityApp.Nationality.RO`) },
                      { value: 'EU', label: translate(`iamdentityApp.Nationality.EU`) },
                      { value: 'NON_EU', label: translate(`iamdentityApp.Nationality.NON_EU`) },
                    ],
                  },
                  ...getFiltersForCustomAttributes(customAttributeDefinitions),
                ]}
              />
            )}
            <tr>
              <th className="hand id-size" onClick={sort('id')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.person.id">ID</Translate>
                  </CustomText>
                  {handleSortIconsTable('id')}
                </div>
              </th>
              <th className="hand" onClick={sort('name')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.person.name">Name</Translate>
                  </CustomText>
                  {handleSortIconsTable('name')}
                </div>
              </th>
              <th className="hand" onClick={sort('firstName')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.person.firstName">First Name</Translate>
                  </CustomText>
                  {handleSortIconsTable('firstName')}
                </div>
              </th>
              <th className="hand" onClick={sort('nationalIdentifier')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.person.nationalIdentifier">National Identifier</Translate>
                  </CustomText>
                  {handleSortIconsTable('nationalIdentifier')}
                </div>
              </th>
              <th className="hand" onClick={sort('gender')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.person.gender">Gender</Translate>
                  </CustomText>
                  {handleSortIconsTable('gender')}
                </div>
              </th>
              <th className="hand" onClick={sort('phoneNumber')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.person.phoneNumber">Phone Number</Translate>
                  </CustomText>
                  {handleSortIconsTable('phoneNumber')}
                </div>
              </th>
              <th className="hand" onClick={sort('communicationEmail')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.person.communicationEmail">Communication Email</Translate>
                  </CustomText>
                  {handleSortIconsTable('communicationEmail')}
                </div>
              </th>
              <th className="hand" onClick={sort('dateOfBirth')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.person.dateOfBirth">Date Of Birth</Translate>
                  </CustomText>
                  {handleSortIconsTable('dateOfBirth')}
                </div>
              </th>
              <th className="hand" onClick={sort('nationality')}>
                <div className={'d-flex-filter'}>
                  <CustomText>
                    <Translate contentKey="iamdentityApp.person.nationality">Nationality</Translate>
                  </CustomText>
                  {handleSortIconsTable('nationality')}
                </div>
              </th>
              {customAttributeDefinitions?.map(cad => (
                <th className="hand" key={cad.id}>
                  <div className={'d-flex-filter'}>
                    <CustomText>{cad.displayName}</CustomText>
                  </div>
                </th>
              ))}
              <th className="hand">
                {/*  <span>*/}
                {/*    <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'}*/}
                {/*            onClick={toggleFilter}>*/}
                {/*      <FontAwesomeIcon icon="magnifying-glass" />*/}
                {/*    </Button>*/}
                {/*  </span>*/}
                {/*<UncontrolledTooltip placement="top" target="toggle-filter">*/}
                {/*  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide*/}
                {/*    Filter</Translate>*/}
                {/*</UncontrolledTooltip>*/}
              </th>
            </tr>
          </thead>
          <tbody>
            {personList.map((person, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Link to={`/person/${person.id}`} color="link">
                    {person.id}
                  </Link>
                </td>
                <td>
                  <CustomText>{person.name}</CustomText>
                </td>
                <td>
                  <CustomText>{person.firstName}</CustomText>
                </td>
                <td>
                  <CustomText>{person.nationalIdentifier}</CustomText>
                </td>
                <td>
                  <CustomText>{translate(`iamdentityApp.Gender.${person.gender}`)}</CustomText>
                </td>
                <td>
                  <CustomText>{person.phoneNumber}</CustomText>
                </td>
                <td>
                  <CustomText>{person.communicationEmail}</CustomText>
                </td>
                <td>
                  {person.dateOfBirth ? (
                    <TextFormat type="date" value={person.dateOfBirth} format={convertDateFormat(locale, 'date')} />
                  ) : null}
                </td>
                <td>{translate(`iamdentityApp.Nationality.${person.nationality}`)}</td>
                {customAttributeDefinitions?.map(cad => (
                  <td key={cad.id}>
                    <CustomText>{findCustomAttributeValueByKey(cad, person, locale)}</CustomText>
                  </td>
                ))}

                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`/person/${person.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                      <FontAwesomeIcon icon="eye" /> {/*<span className="d-none d-md-inline">*/}
                      {/*    <Translate contentKey="entity.action.view">View</Translate>*/}
                      {/*  </span>*/}
                    </Button>
                    {(isAdmin || isHRAdmin) && allowManualEntityCreationProp && (
                      <Button
                        tag={Link}
                        to={`/person/${person.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="warning"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" /> {/*<span className="d-none d-md-inline">*/}
                        {/*    <Translate contentKey="entity.action.edit">Edit</Translate>*/}
                        {/*  </span>*/}
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!personList || personList.length < 1) && !loading && (
          <div className="alert alert-warning mt-1">
            <Translate contentKey="iamdentityApp.person.home.notFound">No People found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={personList && personList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex mt-2">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
      <AuditEntityModal
        isOpen={auditModal}
        toggle={toggleAuditModal}
        entityName={AuditEntityName.PERSON}
        toggleAuditModal2={toggleAuditModal2}
        entityFieldName={'name'}
        entityDetailsName={'person'}
        entityLink={'person'}
      />
      <AuditModal isOpen={auditModal2} toggle={() => toggleAuditModal2()} entityId={entityId} entityName={AuditEntityName.PERSON} />
    </Card>
  );
};

export default Person;
