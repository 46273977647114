import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './custom-attribute-definition.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import navigateBack from 'app/shared/components/handlers/buttons/back';

export const CustomAttributeDefinitionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const customAttributeDefinitionEntity = useAppSelector(state => state.customAttributeDefinition.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  return (
    <React.Fragment>
      <Row>
        <Col md="3">
          <Card className="jh-card card-grey p-2">
            <h2 data-cy="customAttributeDefinitionDetailsHeading">
              <Translate contentKey="iamdentityApp.customAttributeDefinition.detail.title">CustomAttributeDefinition</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{customAttributeDefinitionEntity.id}</dd>
              <dt>
                <span id="displayName">
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.displayName">Display Name</Translate>
                </span>
              </dt>
              <dd>{customAttributeDefinitionEntity.displayName}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.description">Description</Translate>
                </span>
              </dt>
              <dd>{customAttributeDefinitionEntity.description}</dd>
              <dt>
                <span id="customAttributeType">
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.customAttributeType">Custom Attribute Type</Translate>
                </span>
              </dt>
              <dd>{customAttributeDefinitionEntity.customAttributeType}</dd>
              <dt>
                <span id="selectableOptions">
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.selectableOptions">Selectable Options</Translate>
                </span>
              </dt>
              <dd>{customAttributeDefinitionEntity.selectableOptions}</dd>
              <dt>
                <span id="displayOrder">
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.displayOrder">Display Order</Translate>
                </span>
              </dt>
              <dd>{customAttributeDefinitionEntity.displayOrder}</dd>
              <dt>
                <span id="visibleInList">
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.visibleInList">Visible In List</Translate>
                </span>
              </dt>
              <dd>{customAttributeDefinitionEntity.visibleInList ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {customAttributeDefinitionEntity.startDate ? (
                  <TextFormat value={customAttributeDefinitionEntity.startDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {customAttributeDefinitionEntity.endDate ? (
                  <TextFormat value={customAttributeDefinitionEntity.endDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="objectTypes">
                  <Translate contentKey="iamdentityApp.customAttributeDefinition.objectTypes">Object Types</Translate>
                </span>
              </dt>
              <dd>
                {customAttributeDefinitionEntity.objectTypes?.map((obType, index) => (
                  <React.Fragment>
                    <Translate contentKey={`iamdentityApp.CustomAttributeApplicableObjectType.${obType.objectType}`}>
                      Object Types
                    </Translate>
                    {index != customAttributeDefinitionEntity.objectTypes?.length - 1 ? ', ' : ''}
                  </React.Fragment>
                ))}
                <dt>
                  <Translate contentKey="global.field.createdBy">Created By</Translate>
                </dt>
                <dd>{customAttributeDefinitionEntity.createdBy ? customAttributeDefinitionEntity.createdBy : ''}</dd>
                <dt>
                  <Translate contentKey="global.field.createdDate">Created Date</Translate>
                </dt>
                <dd>
                  {customAttributeDefinitionEntity.createdDate ? (
                    <TextFormat
                      value={customAttributeDefinitionEntity.createdDate}
                      type="date"
                      format={convertDateFormat(locale, 'date')}
                    />
                  ) : null}
                </dd>
                <dt>
                  <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
                </dt>
                <dd>{customAttributeDefinitionEntity.lastModifiedBy ? customAttributeDefinitionEntity.lastModifiedBy : ''}</dd>
                <dt>
                  <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
                </dt>
                <dd>
                  {customAttributeDefinitionEntity.lastModifiedDate ? (
                    <TextFormat
                      value={customAttributeDefinitionEntity.lastModifiedDate}
                      type="date"
                      format={convertDateFormat(locale, 'date')}
                    />
                  ) : null}
                </dd>
              </dd>
            </dl>
            <div className="btn-group flex-btn-group-container">
              <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={navigateBack} color="info">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              <Button tag={Link} to={`/custom-attribute-definition/${customAttributeDefinitionEntity.id}/edit`} color="warning">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button>
              <Button onClick={toggleAuditModal} color="light">
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.history">History</Translate>
                </span>
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.CUSTOM_ATTRIBUTE_DEFINITION} />
    </React.Fragment>
  );
};

export default CustomAttributeDefinitionDetail;
